(function($){
	"use strict";

    function initializePlugins(element) {
        var wind = $(window);
		// Var Background image
		var pageSection = $(".bg-img, section");
		pageSection.each(function (indx) {
			if ($(this).attr("data-background")) {
				$(this).css("background-image", "url(" + $(this).data("background") + ")");
			}
		});
	
        // Header Sticky
		$(window).on('scroll',function() {
            if ($(this).scrollTop() > 120){  
                $('.navbar-area').addClass("is-sticky");
            }
            else{
                $('.navbar-area').removeClass("is-sticky");
            }
        });
        
        // Mean Menu
		jQuery('.mean-menu').meanmenu({
			meanScreenWidth: "991"
        });

        // Adding aria-label to the menu button
        $('.meanmenu-reveal').attr('aria-label', 'Увімкнути навігацію');
		
        // Button Hover JS
        $(function() {
            $('.default-btn')
            .on('mouseenter', function(e) {
                var parentOffset = $(this).offset(),
                relX = e.pageX - parentOffset.left,
                relY = e.pageY - parentOffset.top;
                $(this).find('span').css({top:relY, left:relX})
            })
            .on('mouseout', function(e) {
                var parentOffset = $(this).offset(),
                relX = e.pageX - parentOffset.left,
                relY = e.pageY - parentOffset.top;
                $(this).find('span').css({top:relY, left:relX})
            });
        });

		// Progress bar
		wind.on('scroll', function () {
			$(".skill-progress .progres").each(function () {
				var bottom_of_object = $(this).offset().top + $(this).outerHeight();
				var bottom_of_window = $(window).scrollTop() + $(window).height();
				var myVal = $(this).attr('data-value');
				if (bottom_of_window > bottom_of_object) {
					$(this).css({
						width: myVal
					});
				}
			});
		});
		
        // Testimonial Slider
		$('.testimonial-slider').owlCarousel({
			loop: true,
			nav: true,
			dots: true,
			autoplayHoverPause: true,
            autoplay: true,
            smartSpeed: 1000,
            margin: 20,
            navText: [
                "<span class='sr-only'>Попередній слайд</span><i class='fa fa-chevron-left'></i>",
                "<span class='sr-only'>Наступний слай</span><i class='fa fa-chevron-right'></i>"
            ],
			responsive: {
                0: {
                    items: 1,
                },
                768: {
                    items: 2,
                },
                1200: {
                    items: 3,
                }
            }
        });

        // Adding aria-label to carousel navigation buttons
        $('.testimonial-slider').on('initialized.owl.carousel changed.owl.carousel', function(event) {
            $('.owl-prev').attr('aria-label', 'Попередній слайд');
            $('.owl-next').attr('aria-label', 'Наступний слайд');
        });
		
        // Partner Logo
        $("#partner-carousel").owlCarousel({
            margin: 0,
            autoplay: true,
            autoplayTimeout: 4000,
            smartSpeed: 800,
            nav: false,
            dots: false,
            autoplayHoverPause: true,
            loop: true,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                },
                768: {
                    items: 3,
                },
                1000: {
                    items: 5,
                },
            },
        });
		
		//  Star Counter
		$('.counter-number').counterUp({
			delay: 15,
			time: 2000
		});
		
        // FAQ Accordion
        $(function() {
            $('.accordion').find('.accordion-title').on('click', function(){
                // Adds Active Class
                $(this).toggleClass('active');
                // Expand or Collapse This Panel
                $(this).next().slideToggle('slow');
                // Hide The Other Panels
                $('.accordion-content').not($(this).next()).slideUp('slow');
                // Removes Active Class From Other Titles
                $('.accordion-title').not($(this)).removeClass('active');		
            });
        });

		// Porfolio isotope and filter
		$(window).on('load', function () {
			var projectIsotope = $('.project-container').isotope({
				itemSelector: '.project-grid-item'
			});
			$('#project-flters li').on('click', function () {
				$("#project-flters li").removeClass('filter-active');
				$(this).addClass('filter-active');
				projectIsotope.isotope({
					filter: $(this).data('filter')
				});
			});
		});
		
        // Go to Top
        $(function(){
            // Scroll Event
            $(window).on('scroll', function(){
                var scrolled = $(window).scrollTop();
                if (scrolled > 600) $('.go-top').addClass('active');
                if (scrolled < 600) $('.go-top').removeClass('active');
            });  
            // Click Event
            $('.go-top').on('click', function() {
                $("html, body").animate({ scrollTop: "0" },  500);
            });

            // Adding aria-label to the "Go to Top" button
            $('.go-top').attr('aria-label', 'Перейти вгору');
        });
        
        // Count Time 
        function makeTimer() {
            var endTime = new Date("November 25, 2024 20:00:00 PDT");			
            var endTime = (Date.parse(endTime)) / 1000;
            var now = new Date();
            var now = (Date.parse(now) / 1000);
            var timeLeft = endTime - now;
            var days = Math.floor(timeLeft / 86400); 
            var hours = Math.floor((timeLeft - (days * 86400)) / 3600);
            var minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600 )) / 60);
            var seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
            if (hours < "10") { hours = "0" + hours; }
            if (minutes < "10") { minutes = "0" + minutes; }
            if (seconds < "10") { seconds = "0" + seconds; }
            $("#days").html(days + "<span aria-hidden='true'> Дні</span>");
            $("#hours").html(hours + "<span aria-hidden='true'> Години</span>");
            $("#minutes").html(minutes + "<span aria-hidden='true'> Хвилини</span>");
            $("#seconds").html(seconds + "<span aria-hidden='true'> Секунди</span>");
            $("#timer-text").text(`${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds remaining.`);
        }
        setInterval(function() { makeTimer(); }, 1000);
		// .- ..- - .... --- .-. ---... / -... .- .-. .- -.- .- .... / - .... . -- . ...
    }

    document.addEventListener('turbo:load', function(event) {        
        initializePlugins(event);
    });

    // [
    //     'turbo:click',
    //     'turbo:before-visit',
    //     'turbo:visit',
    //     'turbo:before-cache',
    //     'turbo:before-render',
    //     'turbo:render',
    //     'turbo:load',
    //     'turbo:morph',
    //     'turbo:before-morph-element',
    //     'turbo:before-morph-attribute',
    //     'turbo:morph-element',
    //     'turbo:submit-start',
    //     'turbo:submit-end',
    //     'turbo:before-frame-render',
    //     'turbo:frame-render',
    //     'turbo:frame-load',
    //     'turbo:frame-missing',
    //     'turbo:before-stream-render',
    //     'turbo:before-fetch-request',
    //     'turbo:before-fetch-response',
    //     'turbo:before-prefetch',
    //     'turbo:fetch-request-error',   
    // ].forEach(function(eventName) {
    //     document.addEventListener(eventName, function(event) {
    //         console.log(`turbo event: ${eventName}`, event);
    //     });
    // });

	// WOW JS
	// $(window).on ('load', function (){
    //     if ($(".wow").length) { 
    //         var wow = new WOW ({
    //             boxClass:     'wow',      // Animated element css class (default is wow)
    //             animateClass: 'animated', // Animation css class (default is animated)
    //             offset:       20,         // Distance to the element when triggering the animation (default is 0)
    //             mobile:       true,       // Trigger animations on mobile devices (default is true)
    //             live:         true,       // Act on asynchronously loaded content (default is true)
    //         });
    //         wow.init();
    //     }
    // });
}(jQuery));
